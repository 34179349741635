import { faCheck, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import {
    logo, vanBg, reviewBg,
    chesterRiverBg, oswestryVanBg, wrexhamThreeStoreyBg,
    contactVanBg, commercialBg, areaMap, paymentsCoverBg,
    affordableIcon, extraIcon, paymentIcon, qualifyIcon, smsIcon, trustIcon,
    WrexhamMap, ChesterMap, OswestryMap, ShrewsburyMap
} from '../assets/ImageAssets'


const en = {
    GLOBAL: {
        logo,
        phone_number: "07920117390",
        email: "niklas.cleaning@yahoo.co.uk",
        schema: {
            name: "Niklas Window & Gutter Cleaning",
            description: "We will make your Windows Absolutely Sparkle! Friendly, thorough, dependable and very affordable.",
            image: logo,
            url: "https://niklaswindowcleaning.co.uk",
            priceRange: "££",
            facebook: "https://www.facebook.com/Niklaswindowcleanig/"
        },
        addresses: {
            wrexham: {
                street: "63 King Street",
                city: "Wrexham",
                postcode: "LL11 1EB"
            },
            chester: {
                street: "45 Victoria Road",
                city: "Chester",
                postcode: "CH4 8ST"
            }

        }
    },
    HOME: {
        hero: {
            background: vanBg,
            title: "Niklas Window & Gutter Cleaning",
            subtitle: "We will make your Windows Absolutely Sparkle! Friendly, thorough, dependable and very affordable.",
            new:"(NEW) With each Gutter Cleaning service, free drone inspection",
            buttons: [
                {
                    text: "Get Quote",
                    icon: faAnglesRight,
                    href: "QUOTE"
                },
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    href: "CONTACT_US"
                }]

        },
        statement: "Our focus is to provide the highest quality of window and gutter cleaning combined with reliable and dependable service whilst maintaining safe working environment.",
        about: {
            title: "What we do",
            paragraph: "We specialise in window and gutter cleaning services domestically and commercially within UK North West area, find out here if we are around your local area. We are a family run business and our focus, pride and joy has been to deliver highest window cleaning services you can depend and trust on all year around.",
            buttons: [
                {
                    text: "Portfolio",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    href: "GALLERY"
                }
            ],
        },
        checklist:
        {
            icon: faCheck,
            data: [
                "Over 12 years of reliable window cleans",
                "North West cleaning services including Chester, Deeside, Wrexham, Oswestry and Shrewsbury",
                "Our staff is trained to provide 'sparkling' quality results with great attention to detail.",
                "We guarantee the best results at the most competitive prices",
                "Hundreds of satisfied customers, and counting...",
                "Fully insured company",
                "We will provide you with honest, no obligation quote with no hidden charges."
            ]
        },
        mission: [
            "At Niklas Window & Gutter Cleaning Services, we bring years of skill, experience, and dedication to our craft. Serving the residents of Chester and North Wales, we've honed our skills to deliver top-notch services that other cleaning companies simply can't match. Our proficient, well-trained team uses state-of-the-art cleaning equipment to ensure you get the highest quality results.",
            "We guarantee a spotless, streak-free finish for your windows, making them sparkle like never before. And, we don't stop at windows! We extend our expertise to gutter cleaning too. With us, your gutters will be clear and functional, protecting your home from potential water damage.",
            "There's a reason why we've earned the trust of homeowners throughout Chester and North Wales - it's our unwavering commitment to provide an unparalleled cleaning service. Our passion shines through in every job we take on, because we're not just cleaning your windows and gutters - we're caring for your home.",
            "So, if you want your windows and gutters clean, look no further, Niklas Window Cleaning is the team."
        ],
        features: {
            title: "Our Promise",
            cards: [
                { icon: smsIcon, title: 'SMS Notification', description: 'A day before the visit we will send you an SMS to notify you of the booking.' },
                { icon: extraIcon, title: 'Free', description: 'With every visit we will clean doors, frames, and sills absolutely free of charge.' },
                { icon: trustIcon, title: 'Reliable', description: 'We can provide you with reliable domestic and commercial services throughout the year.' },
                { icon: qualifyIcon, title: 'Quality', description: 'Sparkling quality cleaning of conservatory roof, extension and skylight as well as gutter clearing using latest technology.' },
                { icon: paymentIcon, title: 'Easy Payments', description: 'No Direct Debits or subscription nonsense. We use GoCardless for quick and efficient payments, alternatively cash or bank transfer for your ease.' },
                { icon: affordableIcon, title: 'Affordable', description: 'Our quote tool provides an optimized way to give you most affordable price.' },
            ]
        },
        slider: {
            title: "Recent Reviews",
            background: reviewBg,
            settings: {
                autoplay: true,
                autoplaySpeed: 5000,
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [{

                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        infinite: true
                    }

                }, {

                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        dots: true
                    }
                }]
            },
            reviews: [
                { name: 'Sarah', origin: 'Shrewsbury', review: 'Efficient and professional service, my windows have never looked better!' },
                { name: 'Tom', origin: 'Chester', review: 'Amazing job on my gutters, they were completely clogged before!' },
                { name: 'Emily', origin: 'Marford', review: 'Punctual and courteous, I highly recommend this service' },
                { name: 'Jake', origin: 'Chester', review: 'Great attention to detail, my windows are sparkling clean!' },
                { name: 'Beth', origin: 'Deeside', review: 'Reasonably priced and high-quality work, very satisfied' },
                { name: 'James A', origin: 'Wrexham', review: 'Thorough job on my gutters, they\'re working like new now.' },
                { name: 'Lucy', origin: 'Gresford', review: 'Prompt and reliable, I\'ll definitely use this service again.' },
            ]
        },
        faq: {
            title: "FAQs",
            cols: 2,
            data: [
                {
                    question: "Do I need to be home on cleaning vist?",
                    answer: "Not at all! We can absolutely work around your busy lifestyle, in fact, majority of our client base are not available on the day of cleaning service. We get all the necessary equipment, get the sparkling job done while you are out, and leave the calling card once we are done. Please leave the gates open, and gate pins available prior to our visit!"
                },
                {
                    question: "Can you clean the sunroof windows above my conservatory or extension?",
                    answer: "We certainly can! Our expert team are able to clean all kinds of tricky windows available to see from ground up. Thanks to our lightweight equipment we can reach and safely clean your windows accurately."
                },
                {
                    question: "Can you clean the windows of 3 storey property?",
                    answer: "Absolutely! Our long-reach and lightweight equipment allows us to not only  clean 3 storys, but 4 and 5 storys high."
                },
                {
                    question: "My gates are locked for security reasons. How will you be able to get around the back?",
                    answer: "We value your safety and security! That is why we integrated SMS notification system, so you know when to expect us. You will receive a SMS notification a day prior to our visit, so you know when to leave that gate open for us. Once done, we will always close the gates upon leaving."
                },
                {
                    question: "Can I pay by direct debit?",
                    answer: "No Direct Debit nonsense! We use GoCardless payment system which allows us to collect payments upon your approval, so you know exactly when window cleaning has been done! "
                },
                {
                    question: "Can I pay by credit card?",
                    answer: "To make a payment for one-off jobs you can still use our GoCardless payment system, alternatively we accept cash, bank transfer, or cheque."
                },
                {
                    question: "Will my window cleaning service happen on thee same day of every month?",
                    answer: "If you are our regular client, most certainly yes, we will leave you a card when to expect us. Usually we arrange visits every 4 weeks, but due to bank holidays and other festive periods regular cleaning service can delay. Should there be any change in your regular visit we will notify you in advance."
                },
                {
                    question: "Do you do one off window cleaning jobs?",
                    answer: "We do one off window cleaning services! One off jobs are slightly more expensive then 4 weekly cleans due to deep cleaning we need to conduct and because our regular clients get bigger discounts."
                },
                {
                    question: "The only access to the back garden is through the house. Will you still access?",
                    answer: "Unfortunately no! Our equipment is meant to be used for external use only and our insurance does not cover internal damages, meaning we can not bring our equipment through your property. However, if the access can be made available through the garage and you accept the responsibility for any damages that could occur from water left on the floor, we would be happy to conduct cleaning."
                },
                {
                    question: "Do you clean during winter?",
                    answer: "Absolutely! We provide reliable and affordable window cleaning services all year round."
                },
                {
                    question: "Do I need to sign a contract?",
                    answer: "Not at all! We are happy to provide you with our sparkling service as long as you're happy with us!"
                },
                {
                    question: "Are you insured in case of an accident?",
                    answer: "Yes, be assured we're insured! Should you require a copy of our insurance policy, just let us know."
                }
            ]
        }
    },
    SERVICE_WC: {
        hero: {
            background: chesterRiverBg,
            title: "Window Cleaning"
        },
        statement: "Our mission has been to provide sparkling windows at a fair price, and we have done ever since without compromising on the quality. We have 100s (and counting) regular, satisfied customers in your local area.",
        about: {
            title: "What we do",
            paragraph: [
                "As window cleaning specialist in Wrexham/Chester, North West you can always expect highest quality at affordable price. The pure water system combined with specialized lightweight brush heads will make all your windows shine. We use super lightweight equipment allows us to access and clean thoroughly trickiest windows.",
                "We guarantee a thorough clean of your regular windows, extension windows, conservatory windows and skylights. As a part of cleaning service we include free cleaning of doors, sills, frames, UPVCs at no extra cost.",
                "If you are within Chester, Wrexham, Oswestry or Shrewsbury, ask your neighbors about Niklas Window Cleaning, we guarantee positive experience every time."
            ]
        },
        checklist: {
            icon: faCheck,
            data: [
                "Pure water cleaning ensures long-time glass cleanness as well as maintaining the appearance of your UPVC frames in a top class condition",
                "Water fed pole window cleaning is now common in the industry and on any commercial site ensures full compliance.",
                "Fully complies with Health & Safety regulations",
                "Cleans windows, window frames and sills effectively",
                "Leaves no detergent residue",
                "Eliminates the need for ladders",
                "Reaches inaccessible windows",
                "Reaches windows above obstructed areas such as car parks and flowerbeds, etc.",
                "Environmentally friendly – no chemicals or detergents are used, just pure water",
                "Privacy is maintained and disturbance is greatly reduced"
            ]
        }
    },
    SERVICE_GT: {
        hero: {
            background: chesterRiverBg,
            title: "Gutter Cleaning"
        },
        statement: "Our mission is to provide professional gutter cleaning at affordable price without compromising on quality with our latest specialist technology equipment.",
        about: {
            title: "Gutter cleaning services",
            paragraph: [
                "Our sophisticated gutter cleaning system uses latest technology available on the market allowing us to reach over 18 meters to enable thorough cleaning from all dirt, grime, leaves and other debris.",
                "With our gutter cleaning service your property will be able to control the drainage of water from the roof, which protects the plaster and elevation. This prevents the formation of a large amount of algae, fungus or moss from settling on the wall.",
                "We're able to safely clean gutters all around your property, garages, extensions and conservatories without risk.",
                "As gutter cleaning specialist you can always expect highest quality at affordable price.",
                "If you are within Chester, Wrexham, Oswestry or Shrewsbury, ask your neighbors about Niklas Gutter Cleaning services, we guarantee satisfaction."
            ]
        },
        checklist: {
            icon: faCheck,
            data: [
                "(NEW) Photographic evidence via drone service",
                "Highly experienced gutter cleaning professional.",
                "Ability to reach high gutters, over 18m high, that is over 3 storeys high!",
                "Protects your elevation from algae, mold and moss from setting on the wall.",
                "We guarantee the best results at the most competitive prices.",
                "Risk free, we are fully insured company",
                "Gutter cleaning services available in Chester, Deeside, Wrexham, Oswestry and Shrewsbury, covering the whole North West."
            ]
        }
    },
    SERVICE_CM: {
        hero: {
            background: commercialBg,
            title: "Commercial Services",
            buttons: [{
                text: "Contact Us",
                icon: faAnglesRight,
                size: "xl",
                href: "CONTACT_US"
            }]
        },
        statement: "We're committed to delivering top-tier commercial cleaning services at competitive prices. By using latest technology, we ensure no compromise on quality, giving your business the sparkle it deserves.",
        about: {
            title: "Commercial window cleaning solutions",
            paragraph: [
                "For years we have been working with our cooperate clients providing them with complete commercial window cleaning solutions.",
                "We take pride in quality of service we provide, the efficiency and reliability we can continuously bring to our clients,  as well as, safety with full compliance of Health & Safety regulations and insurance.",            ]
        },
        checklist: {
            icon: faAnglesRight,
            data: [
                "Offices & Office Units",
                "Managed Residential Estates",
                "Shops & Shopping Centres",
                "Hotels",
                "Bars & Restaurants",
                "Managed Apartment Blocks",
                "Student Accommodation",
                "Leisure Complexes",
                "Stadiums",
                "Care Homes",
                "Car Showrooms",
                "Schools & Colleges",
                "Hospitals & Medical Practices"
            ]
        }
    },
    AREAS: {
        hero: {
            background: areaMap,
            title: "Areas Covered",
            subtitle: "We cover our community across North Wales and Chester, keeping windows sparkling and gutters clear.",
        },
        about: {
            title: "We are your local window & gutter cleaners",
            paragraph: "We offer our domestic and commercial services all around North West area. Our cleaning specialists cover anywhere from Chester (Cheshire) and surrounding areas of Deeside, Connah Quay & Hawarden and Queensferry, to Wrexham (North Wales) and surrounding areas of Mold, Saltney and Broughton, to Oswestry and Shrewsbrury (Shropshire). Get in touch with your us, your local window and gutter cleaners or find out where we are in your local area.",
            buttons: [
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    size: "xl",
                    href: "CONTACT_US"
                }
            ],
            image: ""
        },
        image_list: [
            { title: "Wrexham", image: "" },
            { title: "Chester", image: "" },
            { title: "Oswestry", image: "" },
            { title: "Shrewsbury", image: "" }
        ],
    },
    AREA_CHR: {
        hero: {
            background: chesterRiverBg,
            title: "Chester",
            subtitle: "Chester, we've got you covered! Enjoy crystal-clear windows and debris-free gutters with our expert team.",
        },
        about: {
            title: "Window & Gutter Cleaning in Chester",
            paragraph: "We are also in Chester, making us your local window and gutter cleaners. Our specialists cover North West England from Deeside, Connah Quay & Hawarden to Queensferry and surrounding areas, ask your neighbor about Niklas Window & Gutter Cleaning, or feel free to get in touch.",
            paragraph_two: "Due to popular demand of window cleaners in Chester area we have made our services more available so we can meet all your cleaning requirements (window and gutters) at your doorstep.",
            buttons: [
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    size: "xl",
                    href: "CONTACT_US"
                }
            ],
            map: ChesterMap
        }
    },
    AREA_WXM: {
        hero: {
            background: wrexhamThreeStoreyBg,
            title: "Wrexham",
            subtitle: "Wrexham, stay clean and clear! Our professionals are here for your window and gutter cleaning needs.",
        },
        about: {
            title: "Window & Gutter Cleaning in Wrexham",
            paragraph: "We are primarily based in Wrexham, making us your local window and gutter cleaners. Our specialists cover North Wales from Mold to Saltney to Broughton and surrounding areas, ask your neighbor about Niklas Window & Gutter Cleaning, or feel free to get in touch.",
            buttons: [
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    size: "xl",
                    href: "CONTACT_US"
                }
            ],
            map: WrexhamMap
        }
    },
    AREA_OST: {
        hero: {
            background: oswestryVanBg,
            title: "Oswestry",
            subtitle: "Oswestry, keep your worries free! Shiny windows and clear gutters, that's our guarantee.",
        },
        about: {
            title: "Window & Gutter Cleaning in Oswestry",
            paragraph: "We are based in Oswestry, making us your local window and gutter cleaners. Our specialists cover North Wales and Shropshire including Oswestry and surrounding areas, ask your neighbor about Niklas Window & Gutter Cleaning, or feel free to get in touch.",
            buttons: [
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    size: "xl",
                    href: "CONTACT_US"
                }
            ],
            map: OswestryMap
        }
    },
    AREA_SHR: {
        hero: {
            background: contactVanBg,
            title: "Shrewsbury",
            subtitle: "Shrewsbury, ditch the grime, see clearly! We'll make your windows and gutters shine, sincerely.",
        },
        about: {
            title: "Window & Gutter Cleaning in Shrewsbury",
            paragraph: "We are based in Shrewsbury, making us your local window and gutter cleaners. Our specialists cover North Wales and Shropshire including Shrewsbury and surrounding areas, ask your neighbor about Niklas Window & Gutter Cleaning, or feel free to get in touch.",
            buttons: [
                {
                    text: "Contact Us",
                    icon: faAnglesRight,
                    className: "btn-icon",
                    size: "xl",
                    href: "CONTACT_US"
                }
            ],
            map: ShrewsburyMap
        }
    },
    GALLERY: {
        hero: {
            background: vanBg,
            title: "Our Work",
            subtitle: "See how we've transformed homes across North Wales with our services.",
        },
        statement: "Our client gallery is a testament to our commitment to excellence. Witness the transformation we bring to homes and businesses alike, showcasing our high-quality cleaning services in action.",
        about: {
            title: "Our work gallery",
            paragraph: "We have been on the market for over 11 years, we have established amazing relations with all our clients and we would like to show off some of our window and gutter cleaning servies done both for residential and commercial clients, so you can get that glimpse of the quality you can expect every time we come.",
        },
        masonry: {
            images: []
        }

    },
    QUOTE: {
        hero: {
            background: vanBg,
            title: "Instant Quote",
            subtitle: "Get your instant quote now! Hassle-free, no obligation. Let's start making your home shine today.",
        }
    },
    PAYMENT: {
        hero: {
            background: paymentsCoverBg,
            title: "Payments",
            subtitle: "Secure, quick, and easy!",
        },
        statement: "No more worries about payments! With GoCardless, you choose when to pay. Plus, we've got other easy payment options for you. We want to make your experiences as smooth as possible!",
        about: {
            title: "Making payments is simple",
            paragraph: "We offer a wide range of modern payment systems to make your life easy and keep you always up to date. No typical DirectDebit nonsense – instead we have GoCaardless!",
            paragraph_two: [
                "With <strong>GoCardless</strong> you can make payments easier then ever, it's a simple and secure DirectDebit payment method with a twist. It allows to <strong>only collect payments upon your approval</strong> without hassle of regular subscription, our customers love it!",
                "Simply put your address if the field below and click the button to be redirected to GoCardless Secure Payments."
            ],
            payment_options: [
                "GoCardless (recommended)",
                "Bank Transfer",
                "Cheque",
                "Standing Order",
                "DirectDebit",
            ]
        }

    },
    CONTACT_US: {
        hero: {
            background: vanBg,
            title: "Contect Us",
            subtitle: "Questions? Need a quote? We're here for you! Click 'Contact Us' below for quick, friendly assistance.",
        },
        about: {
            title: "We're here for you",
            paragraph: "It's easy to get in touch, use the contact number or email address provided below or simply fill our form:",
            paragraph_two: "Niklas Window & Gutter Professional Cleaning Services in Chester, Wrexham, Shrewsbury, Deeside, Oswestry , North West, Shropshire and surrounding areas.",
        }
    },
    PRIVACY_POLICY: {
        hero: {
            background: vanBg,
            title: "Privacy Policy",
            subtitle: "We respect your privacy.",
        },
        content: [
            {
                title: "Personal Information",
                paragraph: "We only collect personal information for contact purposes. No personal data is stored in a database on this site."
            },
            {
                title: "Use of Cookies and Other Tracking Technologies",
                paragraph: "Our website uses cookies and similar technologies, including Google Analytics and Facebook Pixel, to enhance your experience and to analyze performance and traffic on our site. These tools collect information sent by your device or our site, which we use to improve our services and marketing efforts. By using our website, you consent to the use of these technologies. You can disable these by changing your browser settings."
            },
            {
                title: "Third-Party Services",
                paragraph: "We use GoCardless as our subscription payment provider. Any payment information provided by you is processed and stored by GoCardless. We recommend reviewing GoCardless's Privacy Policy for more information about their data processing practices."
            },
            {
                title: "Sharing of Your Information",
                paragraph: "We do not share or sell your data to other third-party services or companies. Any data we collect is for the sole purpose of improving our services and communication with you."
            },
            {
                title: "Links to Other Websites",
                paragraph: "Our website contains links to other external websites. Please be aware that we are not responsible for the privacy practices of these other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of each website that collects personal information."
            },
            {
                title: "Updating Your Information",
                paragraph: "Since we do not store personal data in a database, users are unable to update or delete their personal information on our site."
            }
        ]
    }
}


export const {
    GLOBAL,
    HOME,
    SERVICE_WC, SERVICE_GT, SERVICE_CM,
    AREAS, AREA_CHR, AREA_WXM, AREA_OST, AREA_SHR,
    GALLERY,
    QUOTE,
    PAYMENT,
    CONTACT_US,
    PRIVACY_POLICY
} = en;