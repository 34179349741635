const service_prices = {
    bangalow: {
        two: {
            none: {
                clean: 65,
                clear: 65,
                full: 99
            },
            conservatory: {
                clean: 75,
                clear: 70,
                full: 115
            }
        },
        three: {
            none: {
                clean: 80,
                clear: 75,
                full: 135
            },
            conservatory: {
                clean: 95,
                clear: 90,
                full: 150
            }
        },
        four: {
            none: {
                clean: 95,
                clear: 90,
                full: 159
            },
            conservatory: {
                clean: 105,
                clear: 99,
                full: 169
            }
        },
        five: {
            none: {
                clean: 115,
                clear: 110,
                full: 189
            },
            conservatory: {
                clean: 120,
                clear: 115,
                full: 199
            }
        }
    },
    house: {
        two: {
            none: {
                clean: 65,
                clear: 65,
                full: 99
            },
            conservatory: {
                clean: 75,
                clear: 70,
                full: 115
            },
            two_storey: {
                clean: 65,
                clear: 65,
                full: 99
            },
            three_storey: {
                clean: 95,
                clear: 90,
                full: 150
            }
        },
        three: {
            none: {
                clean: 85,
                clear: 80,
                full: 135
            },
            conservatory: {
                clean: 90,
                clear: 85,
                full: 145
            },
            two_storey: {
                clean: 85,
                clear: 80,
                full: 135
            },
            three_storey: {
                clean: 115,
                clear: 110,
                full: 189
            }
        },
        four: {
            none: {
                clean: 95,
                clear: 90,
                full: 155
            },
            conservatory: {
                clean: 105,
                clear: 99,
                full: 165
            },
            two_storey: {
                clean: 95,
                clear: 90,
                full: 155
            },
            three_storey: {
                clean: 95,
                clear: 95,
                full: 175
            }
        },
        five: {
            none: {
                clean: 105,
                clear: 99,
                full: 170
            },
            conservatory: {
                clean: 115,
                clear: 105,
                full: 185
            },
            two_storey: {
                clean: 105,
                clear: 99,
                full: 170
            },
            three_storey: {
                clean: 120,
                clear: 115,
                full: 215
            }
        },
        five_plus: {
            none: {
                clean: 120,
                clear: 110,
                full: 210
            },
            conservatory: {
                clean: 130,
                clear: 120,
                full: 220
            },
            two_storey: {
                clean: 120,
                clear: 110,
                full: 210
            },
            three_storey: {
                clean: 130,
                clear: 120,
                full: 220
            }
        }

    }
};

export default service_prices;